import commonHelper from '@/app/utility/common.helper.utility';
import commonModalOracle from '../../../provisionsAdmin/commonModalOracle';
export default {
  name: 'generateBill',
  props: ['billRowData'],
  components: { commonModalOracle },
  watch: {
    currentPage: function() {
      this.getGenerateBillData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getGenerateBillData();
    }
  },
  data() {
    return {
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      legalEntity: null,
      period: null,
      state: null,
      costCenter: null,
      customer: {
        text: null,
        value: null
      },
      customerSite: null,
      gstNum: null,
      address: null,
      generateBillData: [],
      generateBillFields: [
        {
          key: 'dtl_id',
          label: 'Dtl Id',
          class: 'text-center'
        },
        {
          key: 'doc_sequence_value',
          label: 'Doc Sequence Value',
          class: 'text-center'
        },
        {
          key: 'invoice_num',
          label: 'Invoice Num',
          class: 'text-center'
        },
        {
          key: 'invoice_date',
          label: 'Invoice Date',
          class: 'text-center'
        },
        {
          key: 'debit',
          label: 'Debit',
          class: 'text-center'
        },
        {
          key: 'credit',
          label: 'Credit',
          class: 'text-center'
        },
        {
          key: 'net_amt',
          label: 'Net Amount',
          class: 'text-center'
        },
        {
          key: 'amount_already_billed',
          label: 'Amt. Already Billed',
          class: 'text-center'
        },
        {
          key: 'amount_not_to_be_billed',
          label: 'Amt. Not To Be Billed',
          class: 'text-center'
        },
        {
          key: 'amount_remaining',
          label: 'Remaining Amt.',
          class: 'text-center'
        },
        {
          key: 'amount_to_be_billed',
          label: 'Amt. To Be Billed',
          class: 'text-center'
        },
        {
          key: 'tax',
          label: 'Tax Category',
          class: 'text-center'
        }
      ],
      editMode: false,
      modalType: null,
      requestPara: {},
      showCommonModal: false
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    if (this.billRowData) {
      this.legalEntity = this.billRowData.le_id;
      this.period = this.billRowData.period_name;
      this.state = this.billRowData.state;
      this.costCenter = this.billRowData.cost_center;
      this.getGenerateBillData();
    }
  },
  methods: {
    getGenerateBillData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.billRowData.le_id_code,
        period_name: this.billRowData.period_name,
        state: this.billRowData.state,
        cost_center: this.billRowData.cost_center
      };
      this.loader = true;
      this.$store
        .dispatch('fcBilling/getGenerateBillData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page.map(ele => {
              ele.net_amt = +ele.debit - +ele.credit;
              ele.amount_remaining =
                +ele.debit -
                +ele.credit -
                +ele.amount_already_billed -
                +ele.amount_not_to_be_billed;
              return ele;
            });
            this.generateBillData = result;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    calculateRemainingAmt(item) {
      item.amount_to_be_billed = 0;
      if (item.amount_not_to_be_billed > item.net_amt) {
        item.amount_not_to_be_billed = 0;
        item.amount_remaining =
          +item.net_amt -
          +item.amount_already_billed -
          +item.amount_not_to_be_billed;
        this.$bvToast.toast(
          'Amount not to be billed should not exceed the net amount.',
          {
            title: 'Alert',
            variant: 'danger',
            solid: true
          }
        );
      } else {
        item.amount_remaining =
          +item.net_amt -
          +item.amount_already_billed -
          +item.amount_not_to_be_billed;
      }
    },
    validateAmtBilled(item) {
      if (item.amount_to_be_billed > item.amount_remaining) {
        item.amount_to_be_billed = 0;
        this.$bvToast.toast(
          'Amount to be billed should not exceed the remainig amount.',
          {
            title: 'Alert',
            variant: 'danger',
            solid: true
          }
        );
      }
    },
    showHideCommonModal(flag, type) {
      this.modalType = type;
      this.requestPara.reqType = type === 'customer' ? 'customer' : 'sites';
      if (flag) {
        if (this.modalType === 'customer_site') {
          this.requestPara.customerId = this.customer.value;
        }
      }
      if (this.modalType === 'customer_site' && !this.customer.value) {
        this.showCommonModal = false;
        this.$bvToast.toast('Please Select Customer Name first.', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else {
        this.showCommonModal = flag;
      }
    },
    mapSelectedVsetData(item) {
      if (this.modalType === 'customer') {
        this.customer = {
          text: item.customer_name,
          value: item.customer_id
        };
        this.customerSite = null;
        this.address = null;
        this.gstNum = null;
      } else if (this.modalType === 'customer_site') {
        this.customerSite = item.Customer_site_name;
        this.address = item.address;
        this.gstNum = item.gst;
      }
      this.showHideCommonModal(false);
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'customer') {
        this.customer = {
          text: null,
          value: null
        };
        this.customerSite = null;
        this.gstNum = null;
        this.address = null;
      } else if (vsetCode === 'customerSite') {
        this.customerSite = null;
        this.gstNum = null;
        this.address = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
